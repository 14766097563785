/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer components {
    .order-pointer {
        cursor: pointer;
    }

    .order-pointer:not(.active):after {
        content: "\2b65";
    }

    .order-pointer.active.desc:after {
        content: "\2b61";
    }
    .order-pointer.active.asc:after {
        content: "\2b63";
    }


}

.previous-order {
    color: red;
}

.no-previous-order {
    color: green;
}